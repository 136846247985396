import { createApp, defineAsyncComponent } from "vue";
import "/styles/app.css";

import SvgVue from 'svg-vue3'
import VueLazyLoad from 'vue3-lazyload'

const VueScrollTo = require('vue-scrollto')

const app = createApp({

  components: {
  },

  data: () => ({
    scrollPosition: 0,
  }),

  computed: {
    isScrolling () {
      return this.scrollPosition > 0
    },
  },

  mounted () {
    if (window.location.hash.substring(0, 1) === "#") {
      this.scrollTo(window.location.hash);
    }
  },

  methods: {
    handleScroll (event) {
      this.scrollPosition = window.pageYOffset != undefined ? window.pageYOffset : window.scrollY
    },
    scrollTo ( to, duration = 300, offset = -80) {
      VueScrollTo.scrollTo(to, duration, {
        easing: 'ease-out',
        offset: offset,
        force: true,
        cancelable: true,
      })
    }
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

});

app.use(VueLazyLoad)
app.use(SvgVue, {
  svgPath: 'resources/svg',
  extract: false,
  svgoSettings: [
      { removeTitle: true },
      { removeViewBox: false },
      { removeDimensions: true }
  ]
})

app.mount("#app");
